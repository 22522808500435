module.exports=﻿[
    {
        "code": "tim",
        "name": "Timpex demo"
    },  
    {
        "code": "asco",
        "name": "Asco Norge AS"
    },
    {
        "code": "bama",
        "name": "Bama Logistikk"
    },
    {
        "code": "srbkc",
        "name": "Bergen Kran og Cargo"
    },
    {
        "code": "bostrand",
        "name": "Bøstrand Transport"
    },
    {
        "code": "dsv",
        "name": "DSV"
    },
    {
        "code": "gasum",
        "name": "Gasum"
    },   
    {
        "code": "lsa",
        "name": "Leiv Sand Transport"
    },
    {
        "code": "ost",
        "name": "Orkla Shipping & Trading AS"
    },
    {
        "code": "sr_primecargo",
        "name": "Prime Cargo"
    },
    {
        "code": "sr_overseas",
        "name": "Overseas Logistics"
    },
    {
        "code": "sr",
        "name": "SR Group AS"
    },
    {
        "code": "sra",
        "name": "Alta Logistics"
    },
    {
        "code": "hallingfrakt",
        "name": "Halling Frakt"
    },
    {
        "code": "thoresen",
        "name": "Thoresen Transport AS"
    },
    {
        "code": "tine",
        "name": "Tine"
    },
    {
        "code": "tnr",
        "name": "TNR"
    },
    {
        "code": "transferd",
        "name": "Transferd"
    },
    {
        "code": "ttn",
        "name": "Thermo-Transit Norge AS"
    },
    {
        "code": "vd",
        "name": "VD Transport"
    },
    {
        "code": "veoymore",
        "name": "Veøy Møre AS"
    },
    {
        "code": "vts",
        "name": "Voss Transportservice AS"
    },
    {
        "code": "toth_bhs",
        "name": "Bergen Havneservice AS"
    },
    {
        "code": "biversen",
        "name": "B. Iversen Spedisjon AS"
    },
    {
        "code": "andersen",
        "name": "R. Andersen Transport AS"
    },
    {
        "code": "agile",
        "name": "Agile Logistics AS"
    },
    {
        "code": "tine_mellomtransport",
        "name": "Tine Mellomtransport"
    },
    {
        "code": "timetrekkeren",
        "name": "Timetrekkeren AS"
    },
    {
        "code": "tttransx",
        "name": "TT Transx"
    },
    {
        "code": "DB Schenke",
        "name": "DB Schenke",
        "url": "http://www.linjegods.no/DavDB/srv_spor_kriterier.html?fraktnr=",
        "external": true
    },
    {
        "code": "veitransport",
        "name": "Veitransport AS"
    },
    {
        "code": "ttfinland",
        "name": "Thermo Transit Finland"
    },
    {
        "code": "litragass",
        "name": "Litra Gass"
    },
    {
        "code": "litra",
        "name": "Litra Termo"
    },
    {
        "code": "ver",
        "name": "Verdal Transportsentral SA"
    },
    {
        "code": "hauglandcloud",
        "name": "Haugland Transport AS",
        "tmsc": true
    },
    {
        "code": "nordvestcloud",
        "name": "Nordvest Transport AS",
        "tmsc": true
    },
    {
        "code": "pentagontransport",
        "name": "Pentagon Transport Nordic AS"
    },
    {
        "code": "mobelfrakt",
        "name": "Møbelfrakt AS"
    },
    {
        "code": "ltr",
        "name": "Logi Trans"
    },
    {
        "code": "norlog-as",
        "name": "Nor-log AS"
    },
    {
        "code": "jacsped",
        "name": "Jacsped"
    },
    {
        "code": "cargolinea",
        "name": "Cargo Linea AS"
    },
    {
      "code": "termopartner",
      "name": "Termo Partner AS"
    },
    {
      "code": "rogalandkran",
      "name": "Rogaland Kran & Transport AS"
    },
    {
      "code": "ltrdk",
      "name": "Logi Trans DK"
    },
    {
      "code": "vognmand",
      "name": "Vognmand Steen M. Hansen"
    },
    {
        "code": "jas",
        "name": "JAS"
      },
      {
        "code": "tonerud",
        "name": "Tonerud Transport"
      },
      {
        "code": "e-trans",
        "name": "E-Trans",
        "tmsc": true
    },
    {
        "code": "egron",
        "name": "Egron Transport AS"
      }
]